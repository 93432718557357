/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router';
import store from '../store/index';
import { Message,Loading} from 'element-ui';

/** 
 * 提示函数 
 * 禁止点击蒙层、显示一秒后关闭
 */
// const tip = (msg,type = 'info',duretion = 1000) => {
// 	Message({
// 		message: msg,
// 		duration: duretion,
// 		type:type
// 	});
// }

let messageInstance;
const tip = (msg,type = 'info',duretion = 1000) => {
    if (messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message({
		message: msg,
		duration: duretion,
		type:type
	});
};

//加载动画变量
let loading;
/** 
 * 开启全局加载动画 
 * 禁止点击蒙层
 */
const startLoading = () => {    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}
/** 
 * 关闭全局加载动画 
 */
const endLoading = () => {    //使用Element loading-close 方法
    loading.close()
}

//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	router.replace({
		path: '/login',
		query: {
			redirect: router.currentRoute.fullPath
		}
	});
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
	// 状态码判断
	switch (status) {
		// 401: 未登录状态，跳转登录页
		case 401:
			tip(other,'error',2000);
			toLogin();
			break;
			// 403 token过期
			// 清除token并跳转登录页
		case 403:
			tip(other,2000);
			localStorage.removeItem('token');
			setTimeout(() => {
				toLogin();
			}, 1000);
			break;
			// 404请求不存在
		case 404:
			tip('请求的资源不存在','error');
			break;
		default:
			tip(other);
	}
}

// 创建axios实例
var instance = axios.create({
	timeout: 1000 * 12,
	baseURL: process.env.VUE_APP_BASE_API,
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
	config => {
		// 登录流程控制中，根据本地是否存在token判断用户的登录情况
		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
		const token = localStorage.getItem('token')
		token && (config.headers.Authorization = token);
		showFullScreenLoading()
		return config;
	},
	error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
	// 请求成功
	res => {
		tryHideFullScreenLoading()
		if(res.headers.hasAuthorization()){
			localStorage.setItem('token',res.headers.getAuthorization());
		}
		if(res.data.code !== 200){
			errorHandle(res.data.code, res.data.message);
			return Promise.reject(res);
		}
		return Promise.resolve(res.data);
	},
	// 请求失败
	error => {
		tryHideFullScreenLoading()
		const {
			response
		} = error;
		if (response) {
			// 请求已发出，但是不在2xx的范围 
			errorHandle(response.status, response.data.message);
			return Promise.reject(response);
		}
	});

export default instance;
