import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('@/views/HomeView.vue'),
		redirect:'/appList',
		children:[
			{
				path: '/appList',
				name: 'appList',
				component: () => import('@/views/LinkJumpManage/AppManage/ListView.vue'),
			},
			{
				path: '/aBPageList',
				name: 'aBPageList',
				component: () => import('@/views/LinkJumpManage/ABPageManage/ListView.vue'),
			},
			{
				path: '/countryList',
				name: 'countryList',
				component: () => import('@/views/LinkJumpManage/CountryManage/ListView.vue'),
			},
			{
				path: '/realTimeData',
				name: 'realTimeData',
				component: () => import('@/views/AdvertiseManage/RealTimeData/ListView.vue'),
			},
			{
				path: '/countClickData',
				name: 'countClickData',
				component: () => import('@/views/AdvertiseManage/CountClickData/ListView.vue'),
			},
			{
				path: '/browsingRecordData',
				name: 'browsingRecordData',
				component: () => import('@/views/AdvertiseManage/BrowsingRecordData/ListView.vue'),
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/LoginView.vue')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
